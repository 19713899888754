<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn
        color="red darken-1"
        small
        outline
        round
        class="text-capitalize"
        @click.stop="dialog = true"
        v-on="on"
      >
        <v-icon> mdi-close-circle-outline</v-icon> Recusar
      </v-btn>
    </template>
    <span>Recusar o Transporte do Manifesto</span>
    <v-dialog
      v-model="dialog"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title class="headline red darken-2 white--text"
        >
          Recusar transporte de um MTR
        </v-card-title>

        <v-card-text>
          Informe o motivo do cancelamento do MTR
          <v-container fluid grid-list-md>
            <v-layout row wrap>
              <v-flex xs12>
                <v-textarea
                  v-model="cancelReason"
                  label="Motivo do Cancelamento do MTR"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="red darken-1"
            flat="flat"
            @click="dialog = false"
          >
            Fechar
          </v-btn>

          <v-btn
            color="green darken-1"
            flat="flat"
            :disabled="cancelReason === ''"
            @click="cancel()"
            :loading="loading"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-tooltip>
</template>

<script>
import mtrService from '@/services/mtr-service';

export default {
  name: 'RefuseShippingMtr',
  props: {
    mtr: {
      default: {},
    },
  },
  data() {
    return {
      dialog: false,
      cancelReason: '',
      loading: false,
    };
  },
  methods: {
    cancel() {
      this.loading = true;
      mtrService
        .cancelMtr(this.mtr.id, this.cancelReason)
        .then(() => {
          this.showSnackBar('MTR Cancelado com Sucesso', 'success', 'mdi-check');
          this.dialog = false;
          this.$store.dispatch('mtr/setMtrStatusOnGeneratorsList', { mtrId: this.mtr.id, status: 'cancelled' });
        })
        .catch(({ response }) => {
          this.showSnackBar(response.data.join(', '), 'error', 'mdi-alert-outline');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showSnackBar(message, color, messageIcon) {
      this.$store.dispatch('application/toggleSnackbar', {
        show: true,
        timeout: 6000,
        color,
        message,
        messageIcon,
      });
    },
  },
  computed: {
    generator() {
      const user = this.$store.getters['auth/currentUser'];
      return user.person;
    },
    hasEmpty() {
      return !this.responsibleName
          || !this.responsibleRole;
    },
  },
};
</script>
