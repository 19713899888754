var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { top: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "text-capitalize",
                    attrs: {
                      color: "red darken-1",
                      small: "",
                      outline: "",
                      round: "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.dialog = true
                      },
                    },
                  },
                  on
                ),
                [
                  _c("v-icon", [_vm._v(" mdi-close-circle-outline")]),
                  _vm._v(" Recusar\n    "),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c("span", [_vm._v("Recusar o Transporte do Manifesto")]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "headline red darken-2 white--text" },
                [_vm._v("\n        Recusar transporte de um MTR\n      ")]
              ),
              _c(
                "v-card-text",
                [
                  _vm._v(
                    "\n        Informe o motivo do cancelamento do MTR\n        "
                  ),
                  _c(
                    "v-container",
                    { attrs: { fluid: "", "grid-list-md": "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("v-textarea", {
                                attrs: {
                                  label: "Motivo do Cancelamento do MTR",
                                },
                                model: {
                                  value: _vm.cancelReason,
                                  callback: function ($$v) {
                                    _vm.cancelReason = $$v
                                  },
                                  expression: "cancelReason",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red darken-1", flat: "flat" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v("\n          Fechar\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green darken-1",
                        flat: "flat",
                        disabled: _vm.cancelReason === "",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.cancel()
                        },
                      },
                    },
                    [_vm._v("\n          Confirmar\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }