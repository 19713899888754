<template>
  <section class="transporter-mtrs" v-if="transporterMtrs != null">
    <v-card class="elevation-10 pa-4">
      <v-layout row justify-space-between>
        <v-flex class="header">
          <h1 class="title">
            Olá,
            <strong>{{ currentUser.person.name }} ({{ cpf_cnpj }})</strong>.
            Você tem {{ cardsLength.all }} MTRs:
          </h1>
        </v-flex>
      </v-layout>

      <v-layout align-center justify-center row>
        <filter-cards
          @changeActive="toggleActive"
          :cards="cards"
          :activeStatus="activeStatus"
        />
        <v-flex xs3>
          <v-text-field
            box
            v-model="searchValue"
            append-outer-icon="mdi-magnify"
            class="custom-field"
            label="Nome, razão social, CPF ou CNPJ"
            append-icon="la-search"
            @input="searchMtrs"
            :loading="loading"
          />
        </v-flex>
      </v-layout>
    </v-card>

    <v-container fluid v-if="!isFetchingMtrs">
      <sort-mtrs :sortBy="sortBy" @selectOrder="sortList" />

      <v-layout
        class="mb-4"
        column
        justify-space-between
        v-for="(mtr, index) in mtrsFiltereds"
        :key="index"
      >
        <v-toolbar dark color="green darken-4" flat dense cad>
          <v-toolbar-title class="subheading">
            <small>MTR</small>
            {{ mtr.number }}
          </v-toolbar-title>

          <v-spacer />

          <v-toolbar-items>
            <v-layout align-center v-if="mtr.status === 'to_receive'">
              Salvo em: {{ format(mtr.created_at, "DD/MM/YYYY") }}
            </v-layout>
            <v-layout
              align-center
              v-else-if="
                isReceived(mtr.status) ||
                  (mtr.status === 'certified' && mtr.cdf_emitted_at !== null)
              "
            >
              Recebido em: {{ format(mtr.received_at, "DD/MM/YYYY") }}
            </v-layout>
          </v-toolbar-items>
        </v-toolbar>

        <v-card>
          <v-card-text>
            <v-layout align-center>
              <v-flex xs5>
                <v-layout column>
                  <strong>Gerador</strong>
                  <span>{{ _.get(mtr, "generator_data.name") }}</span>
                  <v-layout
                    align-center
                    v-if="_.has(mtr, 'generator_data.cpf_cnpj')"
                  >
                    <span class="grey--text">
                      {{
                        mtr.generator_data.cpf_cnpj.length === 14
                          ? "CNPJ"
                          : "CPF"
                      }}: &nbsp;
                    </span>
                    <span class="grey--text">
                      {{ formatCpfCnpj(mtr.generator_data.cpf_cnpj) }}
                    </span>
                  </v-layout>
                </v-layout>
              </v-flex>

              <v-flex xs5>
                <v-layout column>
                  <strong>Destinador</strong>
                  <span>{{ _.get(mtr, "receiver_data.name") }}</span>
                  <v-layout
                    align-center
                    v-if="_.has(mtr, 'receiver_data.cpf_cnpj')"
                  >
                    <span class="grey--text">
                      {{
                        mtr.receiver_data.cpf_cnpj.length === 14
                          ? "CNPJ"
                          : "CPF"
                      }}: &nbsp;
                    </span>
                    <span class="grey--text">
                      {{ formatCpfCnpj(mtr.receiver_data.cpf_cnpj) }}
                    </span>
                  </v-layout>
                </v-layout>
              </v-flex>

              <v-flex xs2>
                <v-layout justify-center>
                  <v-chip
                    disabled
                    :color="chipStatusColor(mtr)"
                    text-color="white"
                  >
                    {{ translatedStatus(mtr.status) }}
                  </v-chip>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-divider />

          <v-card-text>
            <v-layout>
              <v-flex>
                <v-btn
                  round
                  small
                  outline
                  color="grey darken-1"
                  class="text-capitalize"
                  @click="showMtrPdf(mtr.id)"
                  :loading="downloadingMtrReportId === mtr.id"
                  :disabled="isRequesting"
                >
                  <v-icon left size="16">mdi-file-document</v-icon>
                  Visualizar MTR
                </v-btn>
                <v-btn
                  round
                  small
                  outline
                  color="grey darken-1"
                  class="text-capitalize"
                  v-if="
                    mtr.status !== 'to_receive' && mtr.status !== 'rejected'
                  "
                  :disabled="isRequesting"
                  :loading="downloadingMtrReceivementReportId === mtr.id"
                  @click="downloadMtrReceivementReport(mtr.id)"
                >
                  <v-icon left size="16">mdi-file-document</v-icon>
                  Visualizar Relatório de Recebimento
                </v-btn>
              </v-flex>

              <v-spacer />

              <v-btn
                flat
                color="blue"
                v-if="
                  mtr.status === 'received_by_temporary_storage' &&
                    $can('receive-mtr', 'Mtr')
                "
                :disabled="isRequesting"
                @click="receiveMtr(mtr.id)"
              >
                <v-icon color="blue" left size="20">mdi-pencil</v-icon>
                EDITAR RECEBIMENTO
              </v-btn>
              <v-btn
                flat
                color="blue"
                v-if="mtr.status === 'to_receive' && $can('receive-mtr', 'Mtr')"
                :disabled="isRequesting"
                @click="receiveMtr(mtr.id)"
              >
                <v-icon color="blue" left size="20">mdi-checkbox-marked</v-icon>
                RECEBER MTR
              </v-btn>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-layout>
      <v-layout
        v-if="pagination.totalPages >= 1 && !isFetchingMtrs"
        justify-center
        align-center
      >
        <v-pagination
          class="align-self-center"
          v-model="pagination.activePage"
          :length="pagination.totalPages"
          circle
          @input="fetchTemporaryStorageMtrs"
        />
      </v-layout>

      <empty-content class="mt-5" v-if="mtrsFiltereds.length === 0">
        <v-icon class="mb-2" size="56" slot="content">mdi-inbox</v-icon>
        <div class="headline grey--text text--darken-1" slot="message">
          Não há MTR's para exibir
        </div>
      </empty-content>
    </v-container>
    <v-container fluid v-else>
      <v-layout
        v-if="isFetchingMtrs"
        fill-height
        align-content-center
        justify-center
      >
        <v-progress-circular color="primary" indeterminate />
      </v-layout>
    </v-container>
  </section>
</template>

<script>
import colors from "vuetify/es5/util/colors";
import format from "date-fns/format";
import masks from "@/utils/masks";
import formatCpfCnpj from "@/utils/formatCpfCnpj";
import AcceptShippingMtr from "@/components/mtr/transporter/AcceptShippingMtr";
import RefuseShippingMtr from "@/components/mtr/transporter/RefuseShippingMtr";
import FilterCards from "@/components/base/FilterCards.vue";
import mtrService from "@/services/mtr-service";
import PaginationList from "@/components/base/PaginationList.vue";
import EmptyContent from "@/components/base/EmptyContent.vue";
import SortMtrs from "@/components/mtr/list/SortMtrs.vue";
import eventBus from "@/utils/eventBus";

export default {
  name: "transporter-mtrs",
  components: {
    FilterCards,
    EmptyContent,
    PaginationList,
    AcceptShippingMtr,
    RefuseShippingMtr,
    SortMtrs
  },
  mounted() {
    this.fetchTemporaryStorageMtrs();
    eventBus.$on("mtrReceived", () => {
      this.fetchTemporaryStorageMtrs();
    });
  },
  beforeDestroy() {
    eventBus.$off("mtrReceived");
  },
  data() {
    return {
      loading: false,
      pagination: {
        totalPages: 0,
        activePage: 1
      },
      activeStatus: "all",
      sortBy: "created_at",
      formatCpfCnpj,
      format,
      downloadingMtrReportId: false,
      downloadingMtrReceivementReportId: null,
      isRequesting: false,
      isFetchingMtrs: true,
      masks,
      mtrsFiltereds: [],
      cardsLength: {},
      searchValue: ""
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
    transporterMtrs() {
      return this.mtrsFiltereds;
    },
    cards() {
      return [
        {
          title: "TOTAL",
          status: "all",
          amount: this.cardsLength.all,
          borderColor: colors.grey.base,
          backgroundColor: colors.grey.lighten2,
          active: _.isEqual(this.activeStatus, "all")
        },
        {
          title: "A TRANSPORTAR",
          status: "to_receive",
          amount: this.cardsLength.to_receive,
          borderColor: colors.amber.base,
          backgroundColor: colors.amber.lighten5
        },
        {
          title: "RECEBIDOS (AT)",
          status: "received_by_temporary_storage",
          amount: this.cardsLength.received_by_temporary_storage,
          borderColor: colors.blue.darken2,
          backgroundColor: colors.blue.lighten5
        },
        {
          title: "RECEBIDOS",
          status: "received",
          amount: this.cardsLength.received,
          borderColor: colors.blue.darken2,
          backgroundColor: colors.blue.lighten5
        }
      ];
    },
    cpf_cnpj() {
      return formatCpfCnpj(this.currentUser.person.cpf_cnpj);
    }
  },
  methods: {
    isReceived(status) {
      return (
        status === "received" || status === "received_by_temporary_storage"
      );
    },
    fetchTemporaryStorageMtrs() {
      const vm = this;
      this.isFetchingMtrs = true;
      mtrService
        .mtrTemporaryStorage(
          this.searchValue,
          this.pagination.activePage,
          this.activeStatus,
          this.sortBy
        )
        .then(({ data }) => {
          vm.isFetchingMtrs = false;
          vm.mtrsFiltereds = data.mtrs;
          vm.loading = false;
          vm.pagination.totalPages = data.total_pages;
          vm.cardsLength = data.cards;
        })
        .catch(err => {
          this.$store.dispatch("application/toggleSnackbar", {
            show: true,
            timeout: 6000,
            color: "error",
            message: err.message,
            messageIcon: "mdi-alert-outline"
          });
        })
        .finally(() => {
          this.isFetchingMtrs = false;
        });
    },
    searchMtrs() {
      clearTimeout(this.timeout);
      this.loading = true;
      this.timeout = setTimeout(() => {
        this.fetchTemporaryStorageMtrs();
      }, 400);
    },
    sortList(value) {
      this.sortBy = value;
      this.fetchTemporaryStorageMtrs();
    },
    receiveMtr(id) {
      this.$router.push({
        name: "Receber MTR",
        params: { id, status: "received_by_temporary_storage" }
      });
    },
    chipStatusColor(mtr) {
      if (mtr.status === "to_receive") {
        return "amber";
      }
      return "primary";
    },
    toggleActive(card) {
      this.activeStatus = card.status;
      this.pagination.activePage = 1;
      this.fetchTemporaryStorageMtrs();
    },
    downloadMtrReceivementReport(mtrId) {
      this.downloadingMtrReceivementReportId = mtrId;
      this.isRequesting = true;
      mtrService
        .mtrReceivementReport(mtrId)
        .then(data => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(data);
          link.download = "Relatório de Recebimento de MTR.pdf";
          link.click();
        })
        .catch(err => {
          this.$store.dispatch("application/toggleSnackbar", {
            show: true,
            timeout: 6000,
            color: "error",
            message: err.message,
            messageIcon: "mdi-alert-outline"
          });
        })
        .finally(() => {
          this.downloadingMtrReceivementReportId = false;
          this.isRequesting = false;
        });
    },
    translatedStatus(status) {
      return (
        {
          to_receive: "A TRANSPORTAR",
          received: "RECEBIDO",
          received_by_temporary_storage: "ARMAZENADOR TEMPORÁRIO"
        }[status] || "RECEBIDO"
      );
    },
    showMtrPdf(mtrId) {
      this.$router.push({
        name: "Ver PDF MTR",
        params: { id: mtrId, document: "mtr" }
      });
    }
  }
};
</script>
